.card-manejo {
  background: #fff;
  border-radius: 7px;
  box-shadow: 0 0 23px #ececec;
  margin: 10px 15px 20px;
  overflow: hidden;
  padding: 0 20px 10px;

  .card-titulo {
    display: flex;
    width: 100%;
    margin: 0;
    justify-content: space-between;
    height: 56px;
    align-items: center;

    .titulo {
      font-size: 18px;
      margin: 3px 0 0;
      color: #616161;
      font-weight: 600;
    }

    .opcoes {
      display: flex;
      font-size: 20px;
      color: #818181;
      cursor: pointer;
    }
  }

  segment-personalizado {
    margin: 0 0 10px;
    display: block;
  }

  vasta-input {
    display: block;
  }

  & > vasta-input {
    margin-bottom: 10px;
  }

  .campos-avancados {
    display: flex;
    width: 100%;
    gap: 10px;
    padding: 0;
    margin-bottom: 10px;

    vasta-input {
      width: 50%;

      &:first-child {
        padding-right: 5px;
      }

      &:last-child {
        padding-left: 5px;
      }
    }
  }

  .informacoes {
    display: flex;
    padding: 0;
    flex-wrap: wrap;
    gap: 10px 10px;
    margin: 16px 0 10px;
    width: 100%;

    .info-single {
      max-width: 50%;
      flex: 1;
      padding: 0 5px;

      .label {
        display: flex;
        color: #959595;
        font-size: 9px;
        text-transform: uppercase;
        letter-spacing: 0.4px;
      }
      .value {
        display: flex;
        color: #616161;
        font-size: 16px;
        font-weight: 500;
      }

      &.observacao {
        background: #fafbfb;
        max-width: 100%;
        padding: 10px 10px;
        border-radius: 4px;
        margin: 0;
        min-width: 100%;
      }
    }
  }

  .extensao {
    background: #f6f9fc;
    border-top: 1px solid #dce4da;
    display: flex;
    margin: 0 -20px -10px;

    .btn {
      color: #8c8c8c;
      background: #f0f0f0;
      padding: 0 10px;
      display: flex;
      line-height: 50px;
      font-size: 13px;
      font-weight: 600;
      text-transform: uppercase;
      border-radius: 7px;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      text-align: center;

      ion-icon {
        width: 16px;
        height: 16px;
        margin-right: 6px;
      }
    }

    .btn {
      color: #719abd;
      background: #e2ecf4;
      width: 100%;
    }

    .informacao,
    .acao {
      width: 100%;
      padding: 10px;
      display: flex;
      .btn {
        font-size: 12px;
        height: 56px;
        margin-right: 5px;
      }
    }

    .informacao {
      padding: 10px 20px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;

      .label {
        text-transform: uppercase;
        color: #959595;
        font-size: 9px;
      }

      ion-spinner {
        margin: auto;
        color: #6da3f5;
      }

      .valor {
        border: 1px solid #ccc;
        padding: 2px 6px;
        margin: 4px 0 0;
        width: 80px;
        border-radius: 3px;
        background: #fcfcfe;
        border-color: rgb(127, 255, 244);

        &.pesando {
          border-color: orangered;
          animation: blinker 0.5s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
        }
      }
    }
  }

  .container-buttons-selecao-rapida {
    display: flex;
    flex-direction: row;
    padding: 0 10px 10px 10px;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    background-color: #f5f8fb;
    width: auto;
    margin: 16px -20px -10px;

    button {
      margin: 10px 4px 0 0;
      padding: 5px 11px;
      border-radius: 13px;
      color: #668fb3;
      background-color: #e9f1f8;
      display: flex;
      flex-direction: column;
      line-height: 16px;
      align-items: flex-start;

      &.selecionado {
        background-color: #d1e1f0;
      }
    }
  }
}

@keyframes blinker {
  to {
    opacity: 0.3;
  }
}
