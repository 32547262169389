/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import './theme/componente-manejos.scss';
@import './theme/informacoes-animal.scss';
@import './theme/resumo-manejos-animal-single.scss';

ion-alert.prompt-observacao .alert-input-wrapper textarea {
  border: 1px solid #ccc;
  background: #f4f4f7;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
}

ion-icon.verde {
	color: #0d8270;
}

ion-icon.laranja {
	color: #f79f64;
}

ion-icon.vermelho {
	color: #cf7272;
}

ion-icon.preto {
	color: #7e7e7e;
}

ion-icon.azul {
	color: #0b90ad;
}

ion-icon.amarelo {
	color: #e3b573;
}

vasta-input:not(.card-manejo vasta-input) {
  display: block;
  margin-bottom: 10px;
}

body.scanner-active {
  --background: transparent;
  --ion-background-color: transparent;
}

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
  white-space: normal;
}

html.plt-ios .action-sheet-group.sc-ion-action-sheet-md:last-child {
  padding-bottom: var(--ion-safe-area-bottom);
}

vasta-lista {
  height: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;
}

ion-footer:not(:has(ion-toolbar)):not(app-manejo-sequencial ion-footer),
ion-modal ion-footer:not(:has(ion-toolbar)):not(app-manejo-sequencial ion-footer) {
  background: #ffffff;
  --background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-direction: row-reverse;
  padding: 0 15px;
}

.custom-campo {
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  background: #f6f6f6;
  padding: 5px;
  width: 258px;
  margin: auto;
  margin: 15px;
  position: relative;

  .label-custom-campo {
    color: #959595;
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 15px;
  }
}

.btn {
  color: #8c8c8c;
  // background: #f0f0f0;
  background: #f7f7f7;
  padding: 0 10px;
  display: flex;
  line-height: 50px;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 7px;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  text-align: center;
  height: 50px;

  &.salvar {
    color: #55a274;
    background: #d5f1e3;
  }

  &.cancelar {
    background: #f9f5f5;
    color: #c58280;
  }

  &[disabled] {
    pointer-events: none;
    opacity: 0.6;
  }

  &:active {
    opacity: 0.5;
  }

  ion-spinner,
  ion-icon {
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }
}

button:focus {
  outline: none;
}

.subtitulo {
  font-size: x-small;
  color: darkgray;
}

ion-row {
  .subtitulo {
    margin-bottom: 5px;
  }
}

.modal-narrow,
.modal-pequeno {
  .ion-page .scroll-content {
    padding: 0 10px 10px;

    &:before {
      content: '';
      display: table;
      width: 100%;
      height: 10px;
    }
  }
}

ion-modal:not(.overlay-hidden) ~ ion-modal {
  --backdrop-opacity: 0.3!important;
}

.background-input {
  --background: #dddddd48;
  border-radius: 5px;
}

.no-click {
  --ripple-color: transparent;
  --background-hover: transparent;
}

.input-button {
  @extend .no-click;
  font-size: 0.8rem;
  --background: transparent;
  --background-activated: none;
  --color: #000;
  --color-activated: none;
  --background-hover: none;
}

#container {
  text-align: center;

  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;

  color: #8c8c8c;

  margin: 0;
}

#container a {
  text-decoration: none;
}

@media only screen and (min-height: 330px) and (min-width: 768px) {
  .modal-pequeno::part(content) {
    width: 400px;
    left: calc(50% - (400px / 2));
  }

  .modal-narrow::part(content) {
    width: 600px;
    left: calc(50% - (400px / 2));
    top: 0;
    height: 100%;
    border-radius: 0 !important;
  }
}

.alert-button-group {
  button.alert-button {
    &:first-child {
      color: #999;
    }
  }
}

.tags {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  padding: 4px 0;
  height: auto;
  gap: 4px;

  .tag {
    display: inline-block;
    padding: 0 3px;
    font-size: 10px;
    font-weight: 500;
    text-transform: uppercase;
    color: #ffffff;
    background: #555;
    line-height: 14px;
    border-radius: 2px;

    &.produtiva {
      background: #7b7bb9;
    }
    &.reprodutiva {
      background: #a870a2;

      &.vazia {
        background: #eee0ed;
        color: #ba66b1;
      }
    }
    &.sanitaria {
      background: #bf5d3f;
    }

    &.situacao {
      background: #dd812c;
    }
    
    &.brinco {
      background: #FFF3C9;
      color: #2b2b2b;
      font-size: 12px;
    }

    &.morto {
      background: #ececec;
      color: #d32f2f;
    }
  }
}

@font-face {
  font-family: 'Oxygen';
  src: url('assets/fonts/Oxygen-Light.ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Oxygen';
  src: url('assets/fonts/Oxygen-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Oxygen';
  src: url('assets/fonts/Oxygen-Bold.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'FertiliFont';
  src: url('assets/fonts/FertiliFont.woff') format('woff'), url('assets/fonts/FertiliFont.svg?8ra9t4#FertiliFont') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icone-']:not(.coluna),
[class*=' icone-']:not(.coluna) {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'FertiliFont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icone-fertili:before {
  content: "\e900";
}
.icone-bovino:before {
  content: "\e901";
}
.icone-equino:before {
  content: "\e902";
}
.icone-caprino:before {
  content: "\e903";
}
.icone-ovino:before {
  content: "\e904";
}
.icone-suino:before {
  content: "\e905";
}
.icone-galinha:before {
  content: "\e906";
}
.icone-boi-cerca:before {
  content: "\e907";
}
.icone-cerca:before {
  content: "\e908";
}
.icone-dashboard-gauge:before {
  content: "\e909";
}
.icone-dashboard:before {
  content: "\e90a";
}
.icone-brinco:before {
  content: "\e90b";
}
.icone-fertilizacao:before {
  content: "\e90c";
}
.icone-pegadas:before {
  content: "\e90d";
}
.icone-leite:before {
  content: "\e90e";
}
.icone-cadeado:before {
  content: "\e90f";
}
.icone-sair:before {
  content: "\e910";
}
.icone-vasta:before {
  content: "\e911";
}
.icone-peso:before {
  content: "\e912";
}
.icone-leiteiro:before {
  content: "\e913";
}
.icone-corte:before {
  content: "\e914";
}
.icone-relatorio:before {
  content: "\e915";
}
.icone-proprietario:before {
  content: "\e916";
}
.icone-busca:before {
  content: "\e917";
}
.icone-home:before {
  content: "\e918";
}
.icone-grupo:before {
  content: "\e919";
}
.icone-menu:before {
  content: "\e91a";
}
.icone-semen:before {
  content: "\e91b";
}
.icone-ajustes:before {
  content: "\e91c";
}
.icone-financeiro:before {
  content: "\e91d";
}
.icone-chrome:before {
  content: "\e91e";
}
.icone-camera:before {
  content: "\e91f";
}
.icone-image:before {
  content: "\e920";
}
.icone-android:before {
  content: "\e921";
}
.icone-apple:before {
  content: "\e922";
}
.icone-windows:before {
  content: "\e923";
}
.icone-youtube:before {
  content: "\e924";
}
.icone-twitter:before {
  content: "\e925";
}
.icone-whatsapp:before {
  content: "\e926";
}
.icone-instagram:before {
  content: "\e927";
}
.icone-facebook:before {
  content: "\e928";
}
.icone-linkedin:before {
  content: "\e929";
}
.icone-coracao:before {
  content: "\e92a";
}
.icone-estrela:before {
  content: "\e92b";
}
.icone-seta-cima:before {
  content: "\e92c";
}
.icone-seta-direita:before {
  content: "\e92d";
}
.icone-seta-baixo:before {
  content: "\e92e";
}
.icone-seta-esquerda:before {
  content: "\e92f";
}
.icone-seringa:before {
  content: "\e930";
}
.icone-pronto:before {
  content: "\e931";
}
.icone-fechar:before {
  content: "\e932";
}
.icone-menos:before {
  content: "\e933";
}
.icone-mais:before {
  content: "\e934";
}
.icone-ajuda:before {
  content: "\e935";
}
.icone-info:before {
  content: "\e936";
}
.icone-localizacao:before {
  content: "\e937";
}
.icone-notificacao:before {
  content: "\e938";
}
.icone-chat:before {
  content: "\e939";
}
.icone-estoque:before {
  content: "\e93a";
}
.icone-rebanho:before {
  content: "\e93b";
}
.icone-adicionar-bovino:before {
  content: "\e93c";
}
.icone-remover-bovino:before {
  content: "\e93d";
}
.icone-exclamacao:before {
  content: "\e93e";
}
.icone-pesagem-venda:before {
  content: "\e93f";
}
.icone-pesagem-compra:before {
  content: "\e940";
}
.icone-pesagem-abate:before {
  content: "\e941";
}
.icone-pesagem-controle:before {
  content: "\e942";
}
.icone-parto:before {
  content: "\e947";
}
.icone-diagnostico-gestacao:before {
  content: "\e943";
}
.icone-cio:before {
  content: "\e945";
}
.icone-inseminacao-artificial:before {
  content: "\e944";
}
.icone-monta-natural:before {
  content: "\e946";
}
.icone-desmame:before {
  content: "\e94a";
}
.icone-aplicacao-implante:before {
  content: "\e94c";
}
.icone-remocao-implante:before {
  content: "\e94e";
}
.icone-aborto:before {
  content: "\e948";
}
.icone-escore-corporal:before {
  content: "\e949";
}
.icone-alteracao-numero:before {
  content: "\e94b";
}