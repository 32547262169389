.container-nome-ficha {
  display: flex; 
  flex-direction: row; 
  justify-content: space-between; 
  max-width: 100%;

  [ng-reflect-name='female'],
  [aria-label='female'] {
    color: #ff47c3;
    font-size: 15px;
  }
  [ng-reflect-name='male'],
  [aria-label='male'] {
    color: #719abd;
    font-size: 15px;
  }

  .nome-numeracao {
    font-size: 15px;
    overflow: hidden; 
    text-overflow: ellipsis; 
    max-width: 200px; 
    white-space: nowrap;
    margin-bottom: 0;
  }
  
  .btn-menor {
    height: 35px;
  }
}
