// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/

:root {
  --ion-font-family: 'Oxygen', 'Helvetica Neue', sans-serif;

  /** primary **/
  --ion-color-primary: #0E90AD;
  --ion-color-primary-rgb: 14,144,173;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #0c7f98;
  --ion-color-primary-tint: #269bb5;

  --ion-color-fertili: #3E8C83;
	--ion-color-fertili-rgb: 62,140,131;
	--ion-color-fertili-contrast: #ffffff;
	--ion-color-fertili-contrast-rgb: 255,255,255;
	--ion-color-fertili-shade: #377b73;
	--ion-color-fertili-tint: #51988f;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;
  --ion-color-tertiary-claro: #9ce0f54b;
  --ion-color-tertiary-escuro: #0222b1a2;

  --ion-color-tertiary-primeiro-manejo: #5592c4;
  --ion-color-tertiary-label-claro: #56b6d3b9;

  --ion-color-tertiary-toolbar-background: #b9b9b923;
  --ion-color-tertiary-toolbar-label: #464646bd;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** Rosa **/
  --ion-color-rosa-background: #ffc3ed94;
  --ion-color-rosa-label: #b11b8bc2;

  /** Azul **/
  --ion-color-azul-background: #c3d6ff94;
  --ion-color-azul-label: #1b57b1e3;

  --ion-color-male: #60b2e6;
  --ion-color-male-rgb: 96, 178, 230;
  --ion-color-male-contrast: #000000;
  --ion-color-male-contrast-rgb: 0, 0, 0;
  --ion-color-male-shade: #549dca;
  --ion-color-male-tint: #70bae9;

  --ion-color-female: #e679e6;
  --ion-color-female-rgb: 230, 121, 230;
  --ion-color-female-contrast: #000000;
  --ion-color-female-contrast-rgb: 0, 0, 0;
  --ion-color-female-shade: #ca6aca;
  --ion-color-female-tint: #e986e9;
}

.ion-color-male {
  --ion-color-base: var(--ion-color-male);
  --ion-color-base-rgb: var(--ion-color-male-rgb);
  --ion-color-contrast: var(--ion-color-male-contrast);
  --ion-color-contrast-rgb: var(--ion-color-male-contrast-rgb);
  --ion-color-shade: var(--ion-color-male-shade);
  --ion-color-tint: var(--ion-color-male-tint);
}

.ion-color-female {
  --ion-color-base: var(--ion-color-female);
  --ion-color-base-rgb: var(--ion-color-female-rgb);
  --ion-color-contrast: var(--ion-color-female-contrast);
  --ion-color-contrast-rgb: var(--ion-color-female-contrast-rgb);
  --ion-color-shade: var(--ion-color-female-shade);
  --ion-color-tint: var(--ion-color-female-tint);
}

.alertDestroy{
  color: #f19191 !important;
}